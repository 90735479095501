import { sortByProperty } from '@/helpers/sort';
import Triennium from '@/helpers/triennium';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Triennium',
      flex: 1,
      autoHeight: true,
      sortIndex: 0,
      sort: 'asc',
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: (params) => {
        const { positions } = params.data;

        positions.sort((a, b) => b.triennium.number - a.triennium.number);

        return positions
          .map((p) => `<div>${p.triennium.from} - ${p.triennium.to}</div>`)
          .join('');
      },
      comparator: (valueA, valueB) => {
        const trienniumYear = Triennium.calculateNumberFromYear(new Date().getFullYear());

        const valueAPositions = valueA.positions;
        const valueBPositions = valueB.positions;

        const triennialA = valueAPositions?.[0].triennium.number;
        const triennialB = valueBPositions?.[0].triennium.number;

        // Place current triennial at the top
        if (triennialA === trienniumYear) return -1;
        if (triennialB === trienniumYear) return 1;

        // Sort from future to past for other triennials
        return triennialB - triennialA;
      },
    },
    {
      headerName: 'Country/Region',
      field: 'country',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Full Name',
      flex: 1,
      sortIndex: 1,
      sort: 'asc',
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}
