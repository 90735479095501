/**
 * This is a port from PHP version
 * File: packages/core/src/code/Models/Ref/Triennium.php
 */
class Triennium {
  static FIRST_YEAR = 1957;

  static LENGTH = 3;

  constructor(number = null) {
    if (number === null) {
      number = Triennium.calculateNumberFromYear();
    } else {
      this.assertNumber(number);
    }

    this.number = number;
  }

  assertNumber(number) {
    const min = this.min();
    const max = this.max();

    if (number < min) {
      throw new Error(`Triennium number must be higher than ${min}`);
    } else if (number > max) {
      throw new Error(`Triennium number must be lower than ${max}`);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  min() {
    return 1;
  }

  // eslint-disable-next-line class-methods-use-this
  max() {
    return Triennium.calculateNumberFromYear() + 1;
  }

  number() {
    return this.number;
  }

  from() {
    return Triennium.FIRST_YEAR + (this.number - 1) * Triennium.LENGTH;
  }

  to() {
    return this.from() + Triennium.LENGTH;
  }

  toArray() {
    return {
      number: this.number,
      from: this.from(),
      to: this.to(),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  static calculateNumberFromYear(year) {
    if (year === undefined) {
      year = new Date().getFullYear();
    }

    return 1 + Math.floor((year - Triennium.FIRST_YEAR) / Triennium.LENGTH);
  }

  static all() {
    const all = [];
    for (let n = this.min(); n <= this.max(); n++) {
      all.push(new Triennium(n));
    }

    return all;
  }
}

export default Triennium;
